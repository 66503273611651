.DateRangePicker__CalendarDatePeriod--am {
  background-color: white !important;
}
.DateRangePicker__CalendarDatePeriod--pm {
  background-color: white !important;
}
.DateRangePicker__Month {
  margin: 0;
  width: 100%;
}
